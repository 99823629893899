export default [
  {
    title: 'Etat de vente',
    icon: 'HomeIcon',

    children: [
      {
        title: 'Par jour',
        route: 'dashboard',
      },
      {
        title: 'Date a Date',
        route: 'dashboard-date-to-date',
      },
      {
        title: 'Valeur des produits',
        route: 'reporting',
      },
    ],
  },
  {
    title: 'Etat de retour',
    icon: 'HomeIcon',

    children: [
      {
        title: 'Par jour',
        route: 'return-date',
      },
      {
        title: 'Date a Date',
        route: 'return-date-to-date',
      },
    ],
  },
  {
    title: 'Devis',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Devis',
        route: 'devis-list',
      },
      {
        title: 'Ajouter Devis',
        route: 'devis-add',
      },
    ],
  },
  {
    title: 'Historique de vente',
    route: 'commande-list',
    icon: 'HomeIcon',
  },
  {
    title: 'Vente direct / colis',
    route: 'commande-passager-add',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Vente gros',
    route: 'commande-gros-add',
    icon: 'ShoppingCartIcon',
  },

  {
    title: 'Bon de Livraison',
    icon: 'AlignJustifyIcon',
    children: [
      {
        title: 'Bons de Livraison',
        route: 'bon-livraison-list',
      },
      {
        title: 'Ajouter Bon de Livraison',
        route: { name: 'bon-livraison-add' },
      },
    ],
  },
  {
    title: 'Facture',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Factures',
        route: 'facture-list',
      },
      {
        title: 'Ajouter Facture',
        route: { name: 'facture-add' },
      },
    ],
  },
  {
    title: 'Avoir',
    icon: 'AlignJustifyIcon',
    children: [
      {
        title: 'Avoirs',
        route: 'avoir-list',
      },
      {
        title: 'Ajouter Avoir',
        route: { name: 'avoir-add' },
      },
    ],
  },

  {
    title: 'Retour',
    icon: 'AlignJustifyIcon',
    children: [
      {
        title: 'Retour',
        route: 'return-voucher-list',
      },
      {
        title: 'Ajouter Retour',
        route: { name: 'return-voucher-add' },
      },
    ],
  },
  {
    title: 'Produit',
    icon: 'GiftIcon',
    children: [
      {
        title: 'Stock',
        route: 'produit-list',
      },
      {
        title: 'Import stock labo',
        route: 'import-stock-labo',
      },
      {
        title: 'Ajouter Produit',
        route: { name: 'produit-add' },
      },
    ],
  },

  {
    title: 'Client',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Clients',
        route: 'client-list',
      },
      {
        title: 'Ajouter Client',
        route: { name: 'client-add' },
      },
    ],
  },
  // {
  //   title: 'Utilisateur',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Utilisateur',
  //       route: 'utilisateur-list',
  //     },
  //     {
  //       title: 'Ajouter Utilisateur',
  //       route: { name: 'utilisateur-add' },
  //     },
  //   ],
  // },

]
